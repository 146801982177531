import React from 'react'

/**
 * Converts a string with new lines to a string with <br /> tags
 * without dangerouslySetInnerHTML
 * @param text string with new lines (\n)
 */
export function replaceNewLinesWithBreaks(text: string) {
  return text.split('\n').map((text: string, index: number, lines) => (
    <React.Fragment key={index + '-' + text}>
      {text}
      {index !== lines.length - 1 && <br />}
    </React.Fragment>
  ))
}
