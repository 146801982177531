import { PriceRangeWithFacetCount } from './convert-to-range-facet'

export const getToPriceFacetCountsWhenFromPriceIs = (
  priceRanges: PriceRangeWithFacetCount[],
  selectedValue = -1,
): Record<string, number> => {
  let tally = 0

  return [...priceRanges]
    .filter(
      (priceRange: PriceRangeWithFacetCount) =>
        selectedValue === -1 ||
        (priceRange.to ? priceRange.to > selectedValue : false),
    )
    .map((priceRange) => ({
      value: priceRange.to,
      doc_count: (tally += priceRange.count),
    }))
    .reduce((accumulator, currentPriceRange) => {
      const currentValue = currentPriceRange.value

      if (currentValue !== undefined) {
        accumulator[String(currentValue)] = currentPriceRange.doc_count
      }

      return accumulator
    }, {} as Record<string, number>)
}
