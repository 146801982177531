import { HomePage } from '../types/feature-home-page'

const homePage: HomePage = {
  seo: {
    title: 'Buy Used Cars Without The Faff - cinch',
    description:
      'The faff-free way to buy a car online. Thousands of cars in one place, with home delivery and a 14-day money back guarantee.',
  },
  hero: {
    helpMeChoose: {
      mobile: {
        paragraph1: 'Not sure where to start?',
        paragraph2: 'Try our ',
        link: 'Help Me Choose tool',
      },
      desktop: {
        paragraph1: 'Not sure where to start? Try our ',
        link: 'Help Me Choose tool',
      },
    },
  },
  articles: {
    title: 'Articles',
    cta: 'All articles',
  },
  reviews: {
    title: 'Reviews',
    cta: 'All reviews',
  },
  cinchCare: {
    title: 'Worry-free motoring',
    bullets: [
      'Comprehensive lifetime warranty',
      'Servicing by a nationwide provider',
      'The reassurance of RAC breakdown assistance',
      'For less than £35 a month',
    ],
    button: 'Tell me more',
  },
  ourCommitmentSlides: {
    slides: [
      {
        iconName: 'check-circle',
        title: 'cinch quality assured',
        body: 'All cars are thoroughly checked by highly trained technicians and come with a minimum 6 month MOT.',
      },
      {
        iconName: 'cash',
        title: 'Money-back guarantee',
        body: 'Enjoy your cinch car for <a href="/returns">14 days</a> to make sure it’s right for you. Changed your mind? We’ll collect it and give you a full refund. Totally faff-free.',
      },
      {
        iconName: 'shield-heart',
        title: 'Caring for your car',
        body: 'All our cars come with a 90-day warranty, including breakdown assistance. Add <a href="/car-care/cinch-cover">cinchCover</a> at checkout to protect yourself against the cost of mechanical, electrical and cosmetic repairs.',
      },
    ],
  },
  trustpilotReviews: {
    title: 'The nation is cinching it',
    reviews: [],
  },
  sponsorSection: {
    logoAlt: 'England cricket logo',
    imageAlt: 'England cricket players',
  },
  wbac: {
    body: 'Just looking to sell for now? Try our sister site webuyanycar.',
    cta: 'Get a free car valuation',
  },
}
export default homePage
