import { useAnalytics } from '@cinch-nx/shared-analytics'
import cx from 'classnames'
import type { Makes } from '../../types/feature-home-page'
import { Filters } from './filters'
import { HeroHeading } from './hero-heading/hero-heading'
import { HeroSubheading } from './hero-subheading/hero-subheading'
import { HeroLink } from './hero-link/hero-link'
import { HeroImage } from './hero-image/hero-image'
import styles from './hero.module.css'
import type { HomeHeroV2 as HomeHeroV2Content } from '../../types/home-story-content'

import {
  TrustpilotScoreless,
  TrustpilotWidget,
  TrustpilotWidgetTemplates,
  Wrapper,
} from '@cinch-nx/shared-ui'
import { useViewport } from '@cinch-labs/shared-util'
import { TrustpilotOverviewV2 } from '@cinch-nx/landing-ui-shared'
import { Env, readFromEnv } from '@cinch-nx/environments'
import { HeroForm } from './hero-form'
import { useTrustpilotScore } from '@cinch-nx/data-trustpilot'
import { SearchResponse } from '@cinch-nx/data-search'
import { useSsrExperiments } from '@cinch-nx/adobe-server-side'

export type HeroProps = HomeHeroV2Content & {
  starRating: number
  starRatingLabel: string
  numberOfReviews: number
  totalStock: number
  makes: Makes[]
  shouldProdAdobeTargetTestRun?: boolean
  searchResponse?: SearchResponse
}

const HomeHeroV2: React.FC<HeroProps> = (props) => {
  const isPartExEnabledOnHomepage =
    readFromEnv(Env.EnablePXOnHomepageHero) === 'true'
  const { getExperienceIdForExperiment } = useSsrExperiments()

  const shouldShowPartEx = getExperienceIdForExperiment('partex_homepage') === 1

  const {
    makes,
    totalStock,
    starRating,
    starRatingLabel,
    heading,
    subheading,
    heroLink: [heroLink],
    image,
    isMobileImageShown,
    numberOfReviews,
    heroPartExchange,
    isHeroPromotional,
    searchResponse,
  } = props

  const cinchStarRating = useTrustpilotScore()

  const shouldShowTrustPilotScoreless =
    readFromEnv(Env.ShowScorelessTrustpilot) === 'true'

  const shouldShowTruspilotScoreless = cinchStarRating
    ? shouldShowTrustPilotScoreless || cinchStarRating <= 4.2
    : shouldShowTrustPilotScoreless

  const { title, ctaText, label, placeholder, partExTextList } =
    heroPartExchange[0]

  const heroPartExTextList = partExTextList.map((item) => item.text)

  const { vw } = useViewport({
    updateOnResize: true,
    updateOnOrientationChange: true,
  })
  const { trackEvent } = useAnalytics()

  const isMobile = vw < 1024

  return (
    <section className={styles['container']} aria-describedby="hero-heading">
      <div
        className={cx(styles['background'], styles['backgroundGradient'], {
          [styles['backgroundGradientForPromo']]: isHeroPromotional,
        })}
      >
        <div className={styles['heroInner']}>
          <div
            className={cx(styles['textAndImageContainer'], {
              [styles['isMobileImageShown']]: isMobileImageShown,
            })}
          >
            <div className={styles['textContainer']}>
              <HeroHeading heading={heading} />
              <HeroSubheading subheading={subheading} />
              <HeroLink {...heroLink} trackEvent={trackEvent} />
            </div>
            <div className={styles['imageGridContainer']}>
              <HeroImage
                image={image}
                isMobileImageShown={isMobileImageShown}
              />
            </div>
          </div>
          {isPartExEnabledOnHomepage && shouldShowPartEx ? (
            <HeroForm
              makes={makes}
              totalStock={totalStock}
              starRating={starRating}
              starRatingLabel={starRatingLabel}
              numberOfReviews={numberOfReviews}
              title={title}
              ctaText={ctaText}
              label={label}
              placeholder={placeholder}
              partExTextList={heroPartExTextList}
            />
          ) : (
            <Filters
              makes={makes}
              totalStock={totalStock}
              starRating={starRating}
              starRatingLabel={starRatingLabel}
              numberOfReviews={numberOfReviews}
              searchResponse={searchResponse}
            />
          )}
        </div>
      </div>

      {isMobile && (
        <div className={styles['trustpilotMobile']}>
          {shouldShowTruspilotScoreless ? (
            <TrustpilotScoreless numberOfReviews={numberOfReviews} />
          ) : (
            <TrustpilotOverviewV2
              starRating={starRating}
              starRatingLabel={starRatingLabel}
              numberOfReviews={numberOfReviews}
              className={styles['trustpilotOverview']}
            />
          )}
        </div>
      )}
      {!isMobile && (
        <Wrapper
          backgroundColor="#f8f6ff"
          className={{ inner: styles.trustpilotWrapper }}
        >
          {shouldShowTruspilotScoreless ? (
            <TrustpilotScoreless numberOfReviews={numberOfReviews} />
          ) : (
            <TrustpilotWidget
              height="24px"
              widget={
                isMobile
                  ? TrustpilotWidgetTemplates.microStar
                  : TrustpilotWidgetTemplates.microCombo
              }
            />
          )}
        </Wrapper>
      )}
    </section>
  )
}

export default HomeHeroV2
