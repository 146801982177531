import { replaceNewLinesWithBreaks } from '../utils/create-breaks'
import styles from './hero-subheading.module.css'

interface Props {
  subheading: string
}

export function HeroSubheading({ subheading }: Props) {
  return (
    <h2 className={styles['subheading']} data-testid="hero-subheading">
      {replaceNewLinesWithBreaks(subheading)}
    </h2>
  )
}
