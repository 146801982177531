import { Heading } from '@cinch-labs/design-system'
import { StoryBlokRichText } from '@cinch-nx/data-storyblok'
import { renderWithResolver } from '@cinch-nx/landing-ui-shared'
import { SVG } from '@cinch-nx/shared-ui'
import Shield from './assets/shield.svg'
import UnionShield from './assets/union-shield.svg'
import styles from './value-propositions.module.css'

export interface ValuePropositionCardProps {
  heading: string
  body: StoryBlokRichText
  icon: string
}

const ValuePropositions: React.FC<ValuePropositionCardProps> = ({
  heading,
  body,
  icon,
}) => (
  <div className={styles['root']}>
    <div className={styles.imageContainer}>
      {icon === 'shield' ? (
        <SVG className={styles.icon} children={Shield} />
      ) : (
        <SVG className={styles.icon} children={UnionShield} />
      )}
    </div>
    <div>
      <Heading className={styles.heading}>{heading}</Heading>
      <div className={styles.body}>{renderWithResolver(body)}</div>
    </div>
  </div>
)

export default ValuePropositions
