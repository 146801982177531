import { HomeUspSlide } from '../../types/home-story-content'
import { UspSlide } from './usp-slide'
import styles from './usp-container.module.css'

import {
  Carousel,
  CarouselControls,
  CarouselNavigation,
  CarouselPagination,
  CarouselSlide,
  Wrapper,
} from '@cinch-nx/shared-ui'
import { useViewport } from '@cinch-labs/shared-util'

interface Props {
  slides: HomeUspSlide[]
}

export function USPContainer({ slides = [] }: Props) {
  const { vw } = useViewport({
    updateOnResize: true,
    updateOnOrientationChange: true,
  })
  const isTablet = vw >= 768 && vw < 1024

  return (
    <Wrapper
      className={{
        container: styles.carouselWrapper,
        inner: styles.carouselInner,
      }}
    >
      <div
        className={styles.uspContainerDesktop}
        data-testid="USPContainerDesktop"
      >
        {slides.map((slide) => (
          <UspSlide {...slide} key={slide._uid} />
        ))}
      </div>

      <div
        className={styles.uspContainerMobile}
        data-testid="USPContainerMobile"
      >
        <Carousel
          name="usp"
          swiperSettings={{ slidesPerView: isTablet ? 2.1 : 1.1 }}
        >
          {slides.map((slide) => (
            <CarouselSlide key={slide._uid}>
              <UspSlide {...slide} />
            </CarouselSlide>
          ))}
        </Carousel>
        <CarouselControls customClassNames={styles.carouselControls}>
          <CarouselNavigation
            name="usp"
            ariaLabels={{ next: 'Next USP', previous: 'Previous USP' }}
          ></CarouselNavigation>
          <CarouselPagination name="usp"></CarouselPagination>
        </CarouselControls>
      </div>
    </Wrapper>
  )
}
