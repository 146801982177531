import { replaceNewLinesWithBreaks } from '../utils/create-breaks'
import styles from './hero-heading.module.css'

interface Props {
  heading: string
}

export function HeroHeading({ heading }: Props) {
  return (
    <h2 className={styles['heading']} data-testid="hero-heading">
      {replaceNewLinesWithBreaks(heading)}
    </h2>
  )
}
