import NextLink from 'next/link'

import { storyblokEditable } from '@cinch-nx/data-storyblok'
import { TrackingEvent, TrackingEventTypes } from '@cinch-nx/shared-analytics'
import type { LinkWithText } from '../../../types/home-story-content'
import styles from './hero-link.module.css'

export type Props = LinkWithText & {
  trackEvent: (event: TrackingEvent) => void
}

export function HeroLink({ link, text, trackEvent, ...rest }: Props) {
  if (!text || !link.url) {
    return null
  }

  return (
    <NextLink href={link.url} passHref legacyBehavior>
      <a
        {...storyblokEditable({ ...rest, link, text })}
        className={styles['promoLink']}
        target={link.linktype === 'url' ? '_blank' : undefined}
        href="#shop-now"
        onClick={() =>
          trackEvent({
            eventName: `promo hero link`,
            type: TrackingEventTypes.ADOBE,
            data: {
              event: {
                name: 'click',
                category: 'link',
                label: `Promo link click`,
                action: `Promo Link link click`,
              },
            },
          })
        }
      >
        {text}
      </a>
    </NextLink>
  )
}
