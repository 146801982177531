import {
  EventNames,
  TrackingEvent,
  TrackingEventTypes,
} from '@cinch-nx/shared-analytics'
import { datadogRum } from '@datadog/browser-rum'
import { Datadog } from '@cinch-nx/landing-ui-shared'
const { Actions, Context } = Datadog

type TrackEventLabel = 'make' | 'model' | 'monthly price' | 'total price'

const getValueOrAny = (value: string | undefined) => (value ? value : 'any')

type OnChangeProps = {
  trackEventLabel: TrackEventLabel
  trackEventAction: string
  value?: string
  datadogActionName: Datadog.Actions
  trackEventSection?: string
}

export function createTrackers(trackEvent: (event: TrackingEvent) => void) {
  return {
    onChange({
      trackEventLabel,
      trackEventAction,
      value,
      datadogActionName,
      trackEventSection,
    }: OnChangeProps) {
      trackEvent({
        eventName: `Homepage ${trackEventLabel} dropdown`,
        type: TrackingEventTypes.ADOBE,
        data: {
          event: {
            name: EventNames.click,
            category: 'hero',
            action: trackEventAction,
            label: trackEventLabel,
            value: value?.toLowerCase(),
            section: trackEventSection?.toLowerCase(),
          },
        },
      })

      datadogRum.addAction(datadogActionName, {
        service: Context.HERO_SEARCH,
        squad: Context.SQUAD,
        page: window.location.pathname,
        value: value?.toLowerCase(),
      })
    },

    onSubmit(
      selectedMake?: string,
      selectedModel?: string,
      price?: string,
      trackEventSection?: string,
    ) {
      let trackEventName = 'Homepage filter search all cars'
      let actionName = ''

      const searchTrackEventValue = `search all cars make: ${getValueOrAny(
        selectedMake,
      )}, model: ${getValueOrAny(selectedModel)}, ${getValueOrAny(price)}`

      if (selectedMake) {
        trackEventName = 'Homepage filter search now'
        actionName = selectedModel
          ? `${selectedMake},${selectedModel}`
          : `${selectedMake}`
      }

      trackEvent({
        eventName: trackEventName,
        type: TrackingEventTypes.ADOBE,
        data: {
          action: actionName,
          event: {
            name: EventNames.click,
            category: 'hero',
            action: 'submit',
            label: 'search all cars',
            value: searchTrackEventValue,
            section: trackEventSection?.toLowerCase(),
          },
        },
      })

      datadogRum.addAction(Actions.CLICK_SEARCH_CTA, {
        service: Context.HERO_SEARCH,
        squad: Context.SQUAD,
        page: window.location.pathname,
        value: searchTrackEventValue,
      })
    },
  }
}
