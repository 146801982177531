import { FieldOption } from '../../../homepage'

export function decorateOptions(
  options: FieldOption[],
  count: number,
): FieldOption[] {
  const shouldDecorate = !options.some((option) => option.value === 'any')

  if (shouldDecorate) {
    options.unshift({
      label: `Any`,
      value: 'any',
      count: `${count}`,
    })
  }

  return options
}
