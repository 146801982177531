import { ComponentMap } from '@cinch-nx/data-storyblok'

import {
  HelpMeChoose,
  HomeHeroV2,
  PartExchangeSection,
  ValuePropositions,
  WhyCinch,
  UspSlide,
} from '../components'

export const storyblokComponents: ComponentMap = {
  HomeHeroV2: HomeHeroV2,
  HelpMeChooseSection: HelpMeChoose,
  WhyCinchSection: WhyCinch,
  PartExSection: PartExchangeSection,
  ValuePropsCard: ValuePropositions,
  HomeUspSlide: UspSlide,
}
