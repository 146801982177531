import {
  CardWithIcon,
  ContentSection,
  CustomList,
  CustomListItem,
  Heading,
} from '@cinch-labs/design-system'
import { IconNames } from '@cinch-labs/design-system/dist/src/tokens/tokens.types'
import { useViewport } from '@cinch-labs/shared-util'
import {
  StoryblokImage,
  getStoryblokImageServiceUrl,
} from '@cinch-nx/data-storyblok'
import { renderWithResolver } from '@cinch-nx/landing-ui-shared'

import { WhyCinchCard } from '../../types/home-story-content'
import styles from './why-cinch.module.css'

export interface WhyCinchProps {
  title: string
  image: StoryblokImage
  cards: WhyCinchCard[]
}

const WhyCinch: React.FC<WhyCinchProps> = ({ image, title, cards }) => {
  const { vw } = useViewport({
    updateOnResize: true,
    updateOnOrientationChange: true,
  })
  const isMobile = vw < 1024

  return (
    <section className={styles['whyCinchContainer']} data-testid="whycinch">
      <div className={styles['whyCinchInner']}>
        <Heading fontSize="h1" className={styles['whyCinchHeading']}>
          {title}{' '}
        </Heading>

        <div className={styles['contentWrapper']}>
          <CustomList
            customClassNames={{
              bulletContainer: styles['bulletContainer'],
              listItem: styles['listItem'],
            }}
          >
            {cards.map((card) => (
              <CustomListItem
                key={card.name}
                bullet={
                  <CardWithIcon
                    className={styles['cardWithIcon']}
                    iconName={card.icon as IconNames}
                    iconColor="vividViolet"
                    iconSize={37}
                  />
                }
              >
                <ContentSection
                  title={card.title}
                  classNames={{
                    title: styles['contentSectionTitle'],
                    body: styles['contentSectionBody'],
                  }}
                  sectionWrapperClass={styles['contentSectionWrapper']}
                >
                  {renderWithResolver(card.body)}
                </ContentSection>
              </CustomListItem>
            ))}
          </CustomList>
          {!isMobile ? (
            <div
              className={styles['whyCinchImageContainer']}
              data-testid="featured-cards-image-section"
            >
              <img
                src={getStoryblokImageServiceUrl(image.filename, {
                  width: 360,
                  height: 414,
                  filters: [{ quality: 80 }],
                })}
                alt={image.alt ?? ''}
              />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  )
}

export default WhyCinch
