import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { SelectField } from '@cinch-labs/design-system'
import styles from './price.module.css'

import { ANY_PRICE, payFullRange, payMonthlyRange } from '../pay-options'
import { MONTHLY_PRICE, TOTAL_PRICE, onChangeTracking } from '../handlers'
import { Bucket } from '../../../../types/vehicles-data'
import { convertIntoRangeFacet } from './helpers/convert-to-range-facet'
import { fillInMissingRangeFacets } from './helpers/fill-in-missing-range-facets'
import { getToPriceFacetCountsWhenFromPriceIs } from './helpers/get-to-price-facet-counts'
import { createLabelWithFacetCount } from './helpers/create-label-with-facet-count'
import { Datadog } from '@cinch-nx/landing-ui-shared'

const { Actions } = Datadog

export interface PriceProps {
  radioChecked: string
  handleOnChangeTracking: onChangeTracking
  maxTotalPrice: number
  setMaxTotalPrice: Dispatch<SetStateAction<number>>
  priceBucket: Bucket
  monthlyPriceBucket: Bucket
}

export const Price: React.FC<PriceProps> = ({
  monthlyPriceBucket,
  priceBucket,
  radioChecked,
  maxTotalPrice,
  setMaxTotalPrice,
  handleOnChangeTracking,
}) => {
  const toPrice = [maxTotalPrice.toString()]
  const selectedToPrice = toPrice.length === 0 ? ANY_PRICE : Number(toPrice[0])
  const selectedFromPrice = ANY_PRICE
  const useMonthly = radioChecked === MONTHLY_PRICE

  const priceFacets = convertIntoRangeFacet(
    fillInMissingRangeFacets(payFullRange, priceBucket),
  )
  const monthlyPriceFacets = convertIntoRangeFacet(
    fillInMissingRangeFacets(payMonthlyRange, monthlyPriceBucket),
  )

  function onPriceSelected({
    setMaxTotalPrice,
    handleOnChangeTracking,
    event,
  }: {
    setMaxTotalPrice: Dispatch<SetStateAction<number>>
    handleOnChangeTracking: onChangeTracking
    event: ChangeEvent<HTMLSelectElement>
  }) {
    const value = event.target.value

    const id = useMonthly ? MONTHLY_PRICE : TOTAL_PRICE

    const isTotalPrice = id === TOTAL_PRICE

    const priceType = isTotalPrice ? 'total price' : 'monthly price'
    const datadogActionName = isTotalPrice
      ? Actions.SELECT_MAX_PRICE_OPTION
      : Actions.SELECT_MONTHLY_PRICE_OPTION

    handleOnChangeTracking({
      trackEventLabel: priceType,
      trackEventAction: 'select',
      value,
      datadogActionName,
    })
    setMaxTotalPrice(Number.parseInt(value || '0'))
  }
  const getRange = () => (useMonthly ? payMonthlyRange : payFullRange)

  const toPriceSelectOptions = [
    { label: 'Any', value: ANY_PRICE },
    ...getRange().map(({ label, value }) => ({ label, value })),
  ]
  const toPriceFacetCounts = getToPriceFacetCountsWhenFromPriceIs(
    useMonthly ? monthlyPriceFacets : priceFacets,
  )

  const filteredToPriceSelectOptions = toPriceSelectOptions
    .filter((item) => {
      if (selectedFromPrice === ANY_PRICE) return true
      return item.value === ANY_PRICE
    })
    .map((item) => ({
      value: item.value,
      label: createLabelWithFacetCount(
        toPriceFacetCounts,
        item,
        selectedToPrice,
        useMonthly,
      ),
    }))

  return (
    <SelectField
      label="Select price"
      labelHidden
      id="price"
      data-testid="price-select"
      options={filteredToPriceSelectOptions}
      placeholder={useMonthly ? 'Max monthly budget' : 'Max total price'}
      placeholderValue={''}
      value={maxTotalPrice?.toString() ?? '-1'}
      onChange={(event) =>
        onPriceSelected({
          setMaxTotalPrice: setMaxTotalPrice,
          handleOnChangeTracking,
          event,
        })
      }
      error={{ show: false, classNames: { container: styles['errorBox'] } }}
      name="toPrice"
      classNames={{ container: styles['toPriceSelect'] }}
    />
  )
}
