import dynamic from 'next/dynamic'
import type { IconProps } from '@cinch-nx/design-system-icons'
import { Color } from '@cinch-labs/cds-tokens'

interface Props {
  iconName: string
  color?: Color
  size?: 16 | 24
}

export function StoryblokIcon({
  iconName,
  color = 'monochrome-white',
  size = 24,
}: Props) {
  const Icon = dynamic({
    loader: async () => {
      const icons = await import('@cinch-nx/design-system-icons')
      type keys = Exclude<keyof typeof icons, 'BaseIcon'>

      function isIconName(input: string): input is keys {
        return input in icons
      }

      if (!isIconName(iconName)) {
        return icons.BinIcon as React.FC<IconProps<16 | 24>>
      }

      const Icon = icons[iconName]
      return Icon as React.FC<IconProps<16 | 24>>
    },
    ssr: true,
  })

  return <Icon color={color} size={size} />
}
