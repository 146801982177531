import { NextApiRequestCookies } from 'next/dist/server/api-utils'

type CookieConsentPreference = {
  advertising: boolean
  analytics: boolean
  functional: boolean
}

export const cookiesAreInvalid = (cookies: NextApiRequestCookies) => {
  const cookiePreferences = cookies['cp']

  if (cookiePreferences) {
    const parsedCookiePreferences: CookieConsentPreference =
      JSON.parse(cookiePreferences)
    const analyticsPreference = parsedCookiePreferences.analytics

    if (analyticsPreference) {
      return false
    }
  }

  return true
}

export const getAdobeVisitorID = (cookies: NextApiRequestCookies) => {
  const keys = Object.keys(cookies)

  if (!keys) {
    return null
  }

  const amcvCookieKey: string | undefined = keys.find((key) => {
    // eslint-disable-next-line unicorn/better-regex
    const amcvCookieKeyRegex = /AMCV_[a-zA-Z0-9%]+AdobeOrg/g

    return amcvCookieKeyRegex.test(key)
  })

  if (!amcvCookieKey) {
    return null
  }

  const amcvCookieValue = cookies[amcvCookieKey]

  if (!amcvCookieValue) {
    return null
  }

  return amcvCookieValue
}
