import Image from 'next/legacy/image'
import { storyblokImageLoader } from '@cinch-nx/data-storyblok'

import styles from './hero-image.module.css'
import type { HomeHeroV2 } from '../../../types/home-story-content'
import cx from 'classnames'

export function HeroImage({
  isMobileImageShown,
  image,
}: {
  isMobileImageShown: boolean
  image: HomeHeroV2['image']
}) {
  return (
    <div
      className={cx(styles['imageContainer'], {
        [styles['isShownOnMobile']]: isMobileImageShown,
      })}
    >
      <Image
        src={image.filename}
        alt={image.alt ?? 'Hero Image'}
        loader={storyblokImageLoader}
        priority={true}
        layout="intrinsic"
        width={700}
        height={240}
      />
    </div>
  )
}
