import { FilterRangeOption } from '../../../../../types/feature-home-page'

export const createLabelWithFacetCount = (
  facetData: Record<string, number>,
  currentOption: FilterRangeOption,
  selectedPrice = -1,
  useMonthly = false,
) => {
  const { label, value } = currentOption

  const valueAsKey = useMonthly ? value * 100 : value
  const isNotCurrentValue =
    facetData[valueAsKey] !== undefined && selectedPrice !== value

  return `${label} ${
    isNotCurrentValue ? `(${facetData[valueAsKey].toLocaleString()})` : ''
  }`
}
