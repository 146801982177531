import { Heading, Secondary } from '@cinch-labs/design-system'
import { useViewport } from '@cinch-labs/shared-util'
import {
  EventNames,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'
import Image from 'next/legacy/image'

import type { CarShapes as ShopByShapeCardProps } from '../shop-by-shape-component'
import styles from './shop-by-shape-card.module.css'
import Link from 'next/link'
import { ForwardedRef, forwardRef } from 'react'

const ShopByShapeCard = forwardRef(
  (
    {
      title,
      image,
      count,
      minSeats,
      maxSeats,
      filterQuery,
      alt,
    }: ShopByShapeCardProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => {
    const { trackEvent } = useAnalytics()

    const handleClick = () => {
      trackEvent({
        eventName: `Shop by type: ${title}`,
        type: TrackingEventTypes.ADOBE,
        data: {
          event: {
            name: EventNames.click,
            category: 'button',
            label: 'Shop by type',
            action: title.toLowerCase(),
          },
        },
      })
    }

    const { vw } = useViewport({
      updateOnResize: true,
      updateOnOrientationChange: true,
    })
    const isMobile = vw < 1024

    return (
      <Link href={`/used-cars/${filterQuery}`} passHref legacyBehavior>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          data-testid="shop-by-shape-card"
          className={styles['card']}
          onClick={handleClick}
          href="/used-cars"
          ref={ref}
        >
          <Image
            src={image}
            layout="responsive"
            width={248}
            height={115}
            alt={alt}
          />
          <div className={styles['heading']}>
            <Heading element="h4" className={styles['title']}>
              {title}
            </Heading>
            <p
              className={styles['body']}
            >{`${minSeats} - ${maxSeats} seats`}</p>
          </div>

          {isMobile ? (
            <span className={styles['link']}>{`Shop ${count} cars`}</span>
          ) : (
            <Secondary
              element="span"
              classNames={{ button: styles['button'] }}
              aria-label="Shop by shape card"
            >
              {`Shop ${count} cars`}
            </Secondary>
          )}
        </a>
      </Link>
    )
  },
)

export default ShopByShapeCard
