export interface PriceRangeWithFacetCount {
  count: number
  from?: number
  to?: number
}

function convertKeyCountIntoPriceRangeWithFacetCount(
  key: string,
  count: number,
): PriceRangeWithFacetCount {
  const [from, to] = key.split('-')
  const fromAsNumber = Number.parseInt(from, 10)
  const toAsNumber = Number.parseInt(to, 10)
  const response: PriceRangeWithFacetCount = { count }
  if (!Number.isNaN(fromAsNumber)) response.from = fromAsNumber
  if (!Number.isNaN(toAsNumber)) response.to = toAsNumber
  return response
}

function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null
}

/**
 * convertIntoRangeFacet accepts rangeLikeFacet is a key pair value with key in pattern of <value>-<value> e,g, 7000-8000
 */
export function convertIntoRangeFacet(rangeLikeFacet: {
  [key: string]: number
}): PriceRangeWithFacetCount[] {
  return (
    Object.entries(rangeLikeFacet)
      .map(([key, count]) => {
        const rangeFacet: PriceRangeWithFacetCount =
          convertKeyCountIntoPriceRangeWithFacetCount(key, count)
        return key ? rangeFacet : undefined
      })
      // eslint-disable-next-line unicorn/no-array-callback-reference
      .filter(isDefined)
      .sort((a, b) => {
        if (!a.to) return 1
        if (!b.to) return -1
        return a.to - b.to
      })
  )
}
