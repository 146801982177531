import {
  HorizontalTabs,
  HorizontalTabsButton,
  HorizontalTabsList,
  HorizontalTabsPanel,
  HorizontalTabsPanelList,
} from '@cinch-labs/design-system'
import { Makes } from '../../../types/feature-home-page'
import { Filters } from '../filters/filters'
import styles from './hero-form.module.css'
import { HeroPartEx } from '../hero-part-ex/hero-part-ex'
import {
  TrackingEvent,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'

export interface HeroFormProps {
  totalStock: number
  makes: Makes[]
  starRating: number
  section?: string
  starRatingLabel: string
  numberOfReviews: number
  title: string
  ctaText: string
  label: string
  placeholder: string
  partExTextList: string[]
}

const tabsClickEvent = (): TrackingEvent => ({
  eventName: 'home-page-tab-click',
  type: TrackingEventTypes.ADOBE,
  data: {
    event: {
      name: 'click',
      category: 'hp part ex',
      action: 'select',
      value: 'hp part ex tab',
    },
  },
})

export const HeroForm: React.FC<HeroFormProps> = ({
  totalStock,
  makes,
  starRating,
  starRatingLabel,
  numberOfReviews,
  section,
  title,
  ctaText,
  label,
  placeholder,
  partExTextList,
}) => {
  const { trackEvent } = useAnalytics()

  return (
    <HorizontalTabs withBorder={true} activeTabId="button-id-1">
      <HorizontalTabsList ariaLabel="Find a car">
        <HorizontalTabsButton id="button-id-1" panelId="panel-id-1">
          <div className={styles.tabTitle}>{'Find a car'}</div>
        </HorizontalTabsButton>
        <HorizontalTabsButton
          id="button-id-2"
          panelId="panel-id-2"
          onButtonClick={() => trackEvent(tabsClickEvent())}
        >
          <div className={styles.tabTitle}>{'Part exchange'}</div>
        </HorizontalTabsButton>
      </HorizontalTabsList>

      <HorizontalTabsPanelList>
        <HorizontalTabsPanel
          buttonId="button-id-1"
          id="panel-id-1"
          className={styles.filtersContent}
        >
          <Filters
            section={section}
            makes={makes}
            totalStock={totalStock}
            starRating={starRating}
            starRatingLabel={starRatingLabel}
            numberOfReviews={numberOfReviews}
          />
          )
        </HorizontalTabsPanel>
        <HorizontalTabsPanel
          buttonId="button-id-2"
          id="panel-id-2"
          className={styles.filtersContent}
        >
          <HeroPartEx
            title={title}
            ctaText={ctaText}
            label={label}
            placeholder={placeholder}
            partExTextList={partExTextList}
          />
        </HorizontalTabsPanel>
      </HorizontalTabsPanelList>
    </HorizontalTabs>
  )
}
