import { saveCustomerJourneyCookie } from '@cinch-labs/customer-journey-cookies'
import { Heading } from '@cinch-labs/design-system'
import { useStoryblokState } from '@cinch-nx/data-storyblok'
import { Head, StoryblokRenderer } from '@cinch-nx/landing-ui-shared'
import { TrustpilotReviews, Wrapper } from '@cinch-nx/shared-ui'

import {
  OurCommitmentCarousel,
  RecentAndRecommendedVehicles,
  ShopByShape,
  USPContainer,
  WeBuyAnyCar,
} from './components'
import { storyblokComponents } from './data/storyblok-renderer-map'
import type { HomepageProps } from './get-server-side-props'
import styles from './homepage.module.css'
import homePage from './locale/index'
import { SsrExperimentsProvider } from '@cinch-nx/adobe-server-side'

export interface FieldOption {
  count: string
  label: string
  value: string
}

export const LandingFeatureHomePage = ({
  preview,
  vehiclesData,
  trustpilotData,
  story,
  showPersonalisedComponents,
  recentlyViewedListings,
  similarListings,
  pageMeta,
  searchResponse,
}: HomepageProps) => {
  story = useStoryblokState({ story, enableBridge: preview })
  const { ourCommitmentSlides, wbac, trustpilotReviews, seo } = homePage

  saveCustomerJourneyCookie()

  const isProd = process.env['STAGE_NAME'] === 'production'

  // TODO: uncomment when continue with favouriting on home when A&E is back
  // const showFavouritingTab =
  //   readFromEnv(Env.EnableFavouritingHomePageChanges) === 'true'
  // const { status: userStatus } = useUserStore()
  // const { favourites } = useFavouritesStore()
  // this console.logs are required in the ticket to prove we are getting favourites and user Status.
  // console.log('userStatus', userStatus)
  // console.log('favourites', favourites)

  return (
    <SsrExperimentsProvider experiments={pageMeta.experiments}>
      <Head
        {...story}
        fallbackTitle={seo.title}
        fallbackDescription={seo.description}
      />

      {!!story.content.herov2 && (
        <StoryblokRenderer
          block={story.content.herov2}
          components={storyblokComponents}
          props={{
            makes: vehiclesData.makes,
            totalStock: vehiclesData.totalStock,
            starRating: trustpilotData.stars,
            starRatingLabel: trustpilotData.starRatingLabel,
            numberOfReviews: trustpilotData.total,
            searchResponse,
          }}
        />
      )}

      {story.content.usp ? <USPContainer slides={story.content.usp} /> : null}

      {/* TODO: uncomment when continue with favouriting on home when A&E is back */}
      {/* {showFavouritingTab && (
        <div className={styles['favouritingTabText']}>
          Favouriting feature is enabled!
        </div>
      )} */}

      {showPersonalisedComponents ? (
        <Wrapper>
          <Heading element="h2" className={styles['heading']}>
            Welcome back
          </Heading>

          <RecentAndRecommendedVehicles
            recentlyViewedListings={recentlyViewedListings ?? []}
            similarListings={similarListings ?? []}
          />
          <div className={styles['valuePropsCardsContainer']}>
            <StoryblokRenderer
              block={story.content.personalised[0].valuePropositions}
              components={storyblokComponents}
            />
          </div>
          <StoryblokRenderer
            block={story.content.personalised[0].helpMeChoose}
            components={storyblokComponents}
          />
        </Wrapper>
      ) : null}

      <ShopByShape count={vehiclesData.carTypes} />
      {!showPersonalisedComponents ? (
        <StoryblokRenderer
          block={story.content.whyCinch}
          components={storyblokComponents}
        />
      ) : null}

      <StoryblokRenderer
        block={story.content.partEx}
        components={storyblokComponents}
      />
      <TrustpilotReviews
        title={trustpilotReviews.title}
        starRatingLabel={trustpilotData.starRatingLabel}
        numberOfReviews={trustpilotData.total}
        reviews={trustpilotData.reviews}
      />
      {!showPersonalisedComponents ? (
        <OurCommitmentCarousel {...ourCommitmentSlides} />
      ) : null}

      <WeBuyAnyCar {...wbac} />

      {isProd ? (
        <img
          alt="ZeroFox"
          className={styles['zeroFox']}
          src="https://d21y75miwcfqoq.cloudfront.net/786a6533"
        />
      ) : null}
    </SsrExperimentsProvider>
  )
}

export default LandingFeatureHomePage
