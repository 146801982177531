import { Heading } from '@cinch-labs/design-system'
import { Wrapper } from '@cinch-nx/shared-ui'

import { ShopByShapeProps } from '../../types/feature-home-page'
import ShopByShapeCard from './shop-by-shape-card/shop-by-shape-card'
import styles from './shop-by-shape-component.module.css'

export interface CarShapes {
  title: string
  image: string
  count: number
  minSeats: number
  maxSeats: number
  filterQuery: string
  alt: string
}

const ShopByShape: React.FC<ShopByShapeProps> = ({ count }) => {
  const carShapes: CarShapes[] = [
    {
      title: 'SUV',
      image: '/shop-by-shape/suv.png',
      count: count.suv,
      minSeats: 5,
      maxSeats: 7,
      filterQuery: 'suv-body-type',
      alt: 'SUV car',
    },
    {
      title: 'Electric',
      image: '/shop-by-shape/electric.png',
      count: count.electric,
      minSeats: 2,
      maxSeats: 7,
      filterQuery: 'electric-fuel-type',
      alt: 'Electric car',
    },
    {
      title: 'Hatchback',
      image: '/shop-by-shape/hatchback.png',
      count: count.hatchback,
      minSeats: 4,
      maxSeats: 5,
      filterQuery: 'hatchback-body-type',
      alt: 'Hatchback car',
    },
    {
      title: 'Estate',
      image: '/shop-by-shape/estate.png',
      count: count.estate,
      minSeats: 5,
      maxSeats: 7,
      filterQuery: 'estate-body-type',
      alt: 'Estate car',
    },
    {
      title: 'Coupe',
      image: '/shop-by-shape/coupe.png',
      count: count.coupe,
      minSeats: 2,
      maxSeats: 4,
      filterQuery: 'coupe-body-type',
      alt: 'Coupe car',
    },
    {
      title: 'Convertible',
      image: '/shop-by-shape/convertible.png',
      count: count.convertible,
      minSeats: 2,
      maxSeats: 5,
      filterQuery: 'convertible-body-type',
      alt: 'Convertible car',
    },
    {
      title: 'Saloon',
      image: '/shop-by-shape/saloon.png',
      count: count.saloon,
      minSeats: 4,
      maxSeats: 5,
      filterQuery: 'saloon-body-type',
      alt: 'Saloon car',
    },
    {
      title: 'MPV',
      image: '/shop-by-shape/mpv.png',
      count: count.mpv,
      minSeats: 5,
      maxSeats: 7,
      filterQuery: 'mpv-body-type',
      alt: 'Multi-purpose vehicle car',
    },
  ]

  return (
    <Wrapper>
      <Heading element="h3" fontSize="h1" className={styles['heading']}>
        Shop by shape
      </Heading>

      <Wrapper
        isFluid
        className={{
          container: styles['container'],
          inner: styles['inner'],
        }}
      >
        <div className={styles['cards']}>
          {carShapes.map((carShape) => (
            <ShopByShapeCard key={carShape.title} {...carShape} />
          ))}
        </div>
      </Wrapper>
    </Wrapper>
  )
}
export default ShopByShape
