import { PriceData } from '../../pay-options'

export function fillInMissingRangeFacets(
  staticIncrements: PriceData[],
  dynamicRangeFacets: { [key: string]: number },
) {
  const updatedFacets = { ...dynamicRangeFacets }
  staticIncrements.forEach(({ rangeKey }) => {
    if (dynamicRangeFacets[rangeKey] === undefined) {
      updatedFacets[rangeKey] = 0
    }
  })

  return updatedFacets
}
