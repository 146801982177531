import { Primary, Text } from '@cinch-labs/design-system'
import { Form } from 'react-final-form'
import styles from './hero-part-ex.module.css'
import { useCallback } from 'react'
import { BulletTick } from './assets/bullet-tick'
import { Field } from 'react-final-form'
import TextFieldWrapper from './text-field-wrapper/text-field-wrapper.component'
import { TrackingEventTypes, useAnalytics } from '@cinch-nx/shared-analytics'

export interface HeroPartExProps {
  title: string
  ctaText: string
  label: string
  placeholder: string
  partExTextList: string[]
}

const sanitiseVrm = (vrm: string) => vrm.replace(/\s/g, '')

export const partExchangeCalculatorId = 'part-exchange-calculator'
export const partExchangeContentPageUrl = '/part-exchange'
export const partExchangeCalculatorUrl = '/part-exchange/calculator'

export const HeroPartEx: React.FC<HeroPartExProps> = ({
  title,
  ctaText,
  label,
  placeholder,
  partExTextList,
}) => {
  const { trackEvent } = useAnalytics()

  const onCtaClick = useCallback((formData: { vrm: string }) => {
    const sanitisedVrm = sanitiseVrm(formData.vrm)

    trackEvent({
      eventName: 'home-page-part-ex-submit-button',
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: 'click',
          category: 'hp part ex',
          value: 'hp part ex valuation',
        },
      },
    })

    window.location.href = `${partExchangeCalculatorUrl}?vrm=${sanitisedVrm}`
  }, [])

  const trackVrmInputOnClick = () => {
    trackEvent({
      eventName: 'home-page-part-ex-form',
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: 'click',
          category: 'hp part ex',
          value: 'hp part ex car reg',
        },
      },
    })
  }

  return (
    <section id={partExchangeCalculatorId} className={styles.partExContainer}>
      <Text element="h2" type="vehiclePrice" className={styles.heading}>
        {title}
      </Text>

      <Form
        onSubmit={(formData: { vrm: string }) => onCtaClick(formData)}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              id="vrmInput"
              name="vrm"
              required
              component={TextFieldWrapper}
              fullWidth={true}
              aria-label={label}
              classNames={{
                label: { label: styles.vrmInputLabel },
                input: styles.vrmInput,
              }}
              label={label}
              placeholder={placeholder}
              maxLength={10}
              onClick={trackVrmInputOnClick}
            />

            <ul className={styles.partExTextList}>
              {partExTextList.map((text) => (
                <li key={text} className={styles.partExTextListItems}>
                  <BulletTick />
                  {text}
                </li>
              ))}
            </ul>
            <div className={styles.buttonContainer}>
              <Primary type="submit" data-testid="heroPartExButton" isFullWidth>
                {ctaText}
              </Primary>
            </div>
          </form>
        )}
      />
    </section>
  )
}
