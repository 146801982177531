export type PriceData = {
  label: string
  value: number
  rangeKey: string
}

export const ANY_PRICE = -1

export const payFullRange: PriceData[] = [
  { label: '£5,500', value: 5500, rangeKey: '5500-6000' },
  { label: '£6,000', value: 6000, rangeKey: '6000-6500' },
  { label: '£6,500', value: 6500, rangeKey: '6500-7000' },
  { label: '£7,000', value: 7000, rangeKey: '7000-7500' },
  { label: '£7,500', value: 7500, rangeKey: '7500-8000' },
  { label: '£8,000', value: 8000, rangeKey: '8000-8500' },
  { label: '£8,500', value: 8500, rangeKey: '8500-9000' },
  { label: '£9,000', value: 9000, rangeKey: '9000-9500' },
  { label: '£9,500', value: 9500, rangeKey: '9500-10000' },
  { label: '£10,000', value: 10_000, rangeKey: '10000-11000' },
  { label: '£11,000', value: 11_000, rangeKey: '11000-12000' },
  { label: '£12,000', value: 12_000, rangeKey: '12000-13000' },
  { label: '£13,000', value: 13_000, rangeKey: '13000-14000' },
  { label: '£14,000', value: 14_000, rangeKey: '14000-15000' },
  { label: '£15,000', value: 15_000, rangeKey: '15000-20000' },
  { label: '£20,000', value: 20_000, rangeKey: '20000-25000' },
  { label: '£25,000', value: 25_000, rangeKey: '25000-30000' },
  { label: '£30,000', value: 30_000, rangeKey: '30000-40000' },
  { label: '£40,000', value: 40_000, rangeKey: '40000-50000' },
  { label: '£50,000', value: 50_000, rangeKey: '50000-*' },
]

export const payMonthlyRange: PriceData[] = [
  { label: '£50 p/m', value: 50, rangeKey: '50-100' },
  { label: '£100 p/m', value: 100, rangeKey: '100-150' },
  { label: '£150 p/m', value: 150, rangeKey: '150-200' },
  { label: '£200 p/m', value: 200, rangeKey: '200-250' },
  { label: '£250 p/m', value: 250, rangeKey: '250-300' },
  { label: '£300 p/m', value: 300, rangeKey: '300-350' },
  { label: '£350 p/m', value: 350, rangeKey: '350-400' },
  { label: '£400 p/m', value: 400, rangeKey: '400-450' },
  { label: '£450 p/m', value: 450, rangeKey: '450-500' },
  { label: '£500 p/m', value: 500, rangeKey: '500-550' },
  { label: '£550 p/m', value: 550, rangeKey: '550-600' },
  { label: '£600 p/m', value: 600, rangeKey: '600-650' },
  { label: '£650 p/m', value: 650, rangeKey: '650-700' },
  { label: '£700 p/m', value: 700, rangeKey: '700-750' },
  { label: '£750 p/m', value: 750, rangeKey: '750-800' },
  { label: '£800 p/m', value: 800, rangeKey: '800-850' },
  { label: '£850 p/m', value: 850, rangeKey: '850-900' },
  { label: '£900 p/m', value: 900, rangeKey: '900-950' },
  { label: '£950 p/m', value: 950, rangeKey: '950-*' },
]
