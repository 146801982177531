import { Primary, SelectField, Text } from '@cinch-labs/design-system'
import { TrackingEventTypes, useAnalytics } from '@cinch-nx/shared-analytics'
import { useEffect, useState } from 'react'

import { createTrackers } from './tracking'
import { FieldOption, Makes } from '../../../types/feature-home-page'
import styles from './filters.module.css'
import cx from 'classnames'
import { Datadog } from '@cinch-nx/landing-ui-shared'
import { datadogRum } from '@datadog/browser-rum'

import {
  MONTHLY_PRICE,
  TOTAL_PRICE,
  updateModelOptions,
  getSearchButtonText,
  onMakeSelected,
  onModelSelected,
  onSubmit,
  updateSearchButtonLabel,
  updateStockLevels,
  addInitialFetchCacheResponse,
} from './handlers'
import { decorateOptions } from '../utils/decorate-options'
import { Price } from './price/price'
import { Bucket } from '../../../types/vehicles-data'
import { Env, readFromEnv } from '@cinch-nx/environments'
import { SearchResponse } from '@cinch-nx/data-search'
import { useSsrExperiments } from '@cinch-nx/adobe-server-side'

const { Actions, Context } = Datadog

export interface FiltersProps {
  totalStock: number
  makes: Makes[]
  starRating: number
  section?: string
  starRatingLabel: string
  numberOfReviews: number
  searchResponse?: SearchResponse
}

export const Filters: React.FC<FiltersProps> = ({
  totalStock,
  makes,
  starRatingLabel,
  starRating,
  numberOfReviews,
  section,
  searchResponse,
}) => {
  const isPartExEnabledOnHomepage =
    readFromEnv(Env.EnablePXOnHomepageHero) === 'true'
  const { getExperienceIdForExperiment } = useSsrExperiments()

  const shouldShowPartEx = getExperienceIdForExperiment('partex_homepage') === 1

  const { trackEvent } = useAnalytics()
  const [radioChecked, setRadioChecked] = useState<
    'monthly-price' | 'total-price'
  >(MONTHLY_PRICE)
  const [maxTotalPrice, setMaxTotalPrice] = useState<number>(0)
  const [selectedMake, setSelectedMake] = useState<undefined | string>()
  const [selectedModel, setSelectedModel] = useState<undefined | string>()
  const [makeOptions, setMakeOptions] = useState<FieldOption[]>(
    decorateOptions(
      makes.map((make) => ({
        label: `${make.name} (${make.stock})`,
        value: make.name,
        count: `${make.stock}`,
      })),
      totalStock,
    ),
  )
  const [searchButtonText, setSearchButtonText] = useState(
    getSearchButtonText(totalStock),
  )
  const [modelOptions, setModelOptions] = useState<FieldOption[]>([])
  const [priceBucket, setPriceBucket] = useState<Bucket>({})
  const [monthlyPriceBucket, setMonthlyPriceBucket] = useState<Bucket>({})

  if (searchResponse) {
    addInitialFetchCacheResponse(searchResponse)
  }

  const { onChange: handleOnChangeTracking, onSubmit: handleOnSubmitTracking } =
    createTrackers(trackEvent)

  const handleTrackEvent = () => {
    datadogRum.addAction(Actions.CLICK_HMC_LINK_IN_FILTERS_SECTION, {
      service: Context.HERO_HMC,
      squad: Context.SQUAD,
      page: '/',
      category: 'hero',
      label: 'help-me-choose-click - inside find a car',
      value: `help-me-choose-click`,
    })

    trackEvent({
      eventName: `Help me choose inside find a car - click`,
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: 'click',
          category: 'hero',
          label: 'inside find a car',
          action: 'Help me choose',
        },
      },
    })
  }

  useEffect(() => {
    updateSearchButtonLabel({
      selectedMake,
      selectedModel,
      radioChecked,
      maxTotalPrice,
      setButtonText: setSearchButtonText,
    }).catch((error) => {
      setSearchButtonText(getSearchButtonText(totalStock))
      console.error('Unable to update search button label cause:', error)
      datadogRum.addError(error)
    })
  }, [selectedMake, selectedModel, radioChecked, maxTotalPrice])

  useEffect(() => {
    updateStockLevels({
      selectedMake,
      selectedModel,
      maxTotalPrice,
      radioChecked,
      totalStock,
      setModelOptions,
      setMakeOptions,
      setPriceBucket,
      setMonthlyPriceBucket,
    }).catch((error) => {
      setMakeOptions(
        decorateOptions(
          makes.map((make) => ({
            label: `${make.name} (${make.stock})`,
            value: make.name,
            count: `${make.stock}`,
          })),
          totalStock,
        ),
      )
      setModelOptions([])
      console.error('Unable to stock levels:', error)
      datadogRum.addError(error)
    })
  }, [selectedMake, selectedModel, radioChecked, maxTotalPrice])

  useEffect(() => {
    updateModelOptions({ selectedMake, selectedModel, makes, setModelOptions })
  }, [selectedMake, selectedModel, makes])

  useEffect(() => {
    if (!selectedMake && selectedMake !== '') {
      setSelectedMake('')
    }
  }, [selectedMake])

  return (
    <div
      className={cx(styles.container, {
        [styles.heroFormContainer]:
          isPartExEnabledOnHomepage && shouldShowPartEx,
      })}
    >
      <Text element={'h1'} className={styles['heading']}>
        Buy used cars online
      </Text>
      <form
        className={styles['form']}
        onSubmit={(event) =>
          onSubmit({
            handleOnSubmitTracking,
            maxTotalPrice,
            radioChecked,
            selectedMake,
            selectedModel,
            event,
            section,
          })
        }
      >
        <SelectField
          label="Select make"
          labelHidden
          id="make"
          name="make"
          data-testid="make-select"
          options={makeOptions}
          placeholder={'Select make'}
          placeholderValue={'Any'}
          value={selectedMake}
          onChange={(event) =>
            onMakeSelected({
              setSelectedMake,
              setSelectedModel,
              setMakeOptions,
              handleOnChangeTracking,
              event,
              section,
            })
          }
          error={{ show: false, classNames: { container: styles['errorBox'] } }}
        />
        <SelectField
          label="Select model"
          labelHidden
          id="model"
          name="model"
          data-testid="model-select"
          options={modelOptions}
          placeholder={'Select model'}
          placeholderValue={'Any'}
          value={selectedModel}
          onChange={(event) =>
            onModelSelected({
              setSelectedModel,
              handleOnChangeTracking,
              event,
              section,
            })
          }
          disabled={!selectedMake || selectedMake.toLocaleLowerCase() === 'any'}
          error={{ show: false, classNames: { container: styles['errorBox'] } }}
        />
        <fieldset className={styles['radioGroup']}>
          <div className={styles['radioInputContainer']}>
            <label htmlFor={MONTHLY_PRICE} className={styles['priceLabel']}>
              <input
                type="radio"
                id={MONTHLY_PRICE}
                name={MONTHLY_PRICE}
                checked={radioChecked === MONTHLY_PRICE}
                onChange={() => {
                  setRadioChecked(MONTHLY_PRICE)
                  setMaxTotalPrice(0)
                  handleOnChangeTracking({
                    trackEventLabel: 'monthly price',
                    trackEventAction: 'button',
                    value: `${MONTHLY_PRICE} checked`,
                    datadogActionName: Actions.CLICK_MONTHLY_PRICE_RADIO,
                  })
                }}
              />
              Monthly price
            </label>
          </div>
          <div className={styles['radioInputContainer']}>
            <label htmlFor={TOTAL_PRICE} className={styles['priceLabel']}>
              <input
                type="radio"
                id={TOTAL_PRICE}
                name={TOTAL_PRICE}
                checked={radioChecked === TOTAL_PRICE}
                onChange={() => {
                  setRadioChecked(TOTAL_PRICE)
                  setMaxTotalPrice(0)
                  handleOnChangeTracking({
                    trackEventLabel: 'total price',
                    trackEventAction: 'button',
                    value: `${TOTAL_PRICE} checked`,
                    datadogActionName: Actions.CLICK_TOTAL_PRICE_RADIO,
                  })
                }}
              />
              Total price
            </label>
          </div>
        </fieldset>

        <Price
          radioChecked={radioChecked}
          handleOnChangeTracking={handleOnChangeTracking}
          maxTotalPrice={maxTotalPrice}
          setMaxTotalPrice={setMaxTotalPrice}
          priceBucket={priceBucket}
          monthlyPriceBucket={monthlyPriceBucket}
        />

        <div className={styles['buttonContainer']}>
          <Primary
            type="submit"
            classNames={{ button: styles['button'] }}
            data-testid="searchButton"
            isFullWidth
          >
            {searchButtonText}
          </Primary>
        </div>

        <div className={styles['helpMeChoose']}>
          <p>Not sure where to start?</p>
          <span>
            {'Try our '}
            <a
              href="https://www.cinch.co.uk/help-me-choose/most-important"
              className={styles['link']}
              onClick={() => handleTrackEvent()}
            >
              Help Me Choose tool
            </a>
          </span>
        </div>
      </form>
    </div>
  )
}
