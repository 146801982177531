import { TrackingEventTypes } from '@cinch-nx/shared-analytics'
import { storyblokEditable } from '@cinch-nx/data-storyblok'
import { HomeUspSlide } from '../../types/home-story-content'
import { StoryblokIcon } from '../icon/icon'

import styles from './usp-slide.module.css'
import { datadogRum } from '@datadog/browser-rum'
import { Datadog } from '@cinch-nx/landing-ui-shared'
import { useAnalytics } from '@cinch-nx/shared-analytics'

const { Context, Actions } = Datadog

export function UspSlide(blok: HomeUspSlide) {
  const { trackEvent } = useAnalytics()
  const { title, text, icon, link } = blok

  const handleTrackEvent = () => {
    datadogRum.addAction(Actions.CLICK_USP_LINK, {
      service: Context.HERO_USP,
      squad: Context.SQUAD,
      page: '/',
      category: 'hero',
      label: 'usp-click - below main hero',
      value: `${title} usp-click`,
    })

    trackEvent({
      eventName: `${title} usp-click`,
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: 'click',
          category: 'hero',
          label: 'below main hero',
          action: `${title}`,
        },
      },
    })
  }

  return (
    <a
      href={link.url}
      className={styles.slideCard}
      {...storyblokEditable(blok)}
      onClick={() => handleTrackEvent()}
    >
      <StoryblokIcon iconName={icon} color={'primary-purple'} size={24} />
      <h3>{title}</h3>
      <p>{text}</p>
    </a>
  )
}
