export { default as HomeHeroV2 } from './hero-v2/hero'
export { default as ShopByShape } from './shop-by-shape/shop-by-shape-component'
export { default as ShopByShapeCard } from './shop-by-shape/shop-by-shape-card/shop-by-shape-card'
export { default as PartExchangeSection } from './part-ex/part-ex'
export { default as WhyCinch } from './why-cinch/why-cinch'
export { default as OurCommitmentCarousel } from './our-commitment/carousel/our-commitment-carousel'
export { default as OurCommitmentSlides } from './our-commitment/slides/our-commitment-slide'
export { default as WeBuyAnyCar } from './wbac/wbac'
export { RecentAndRecommendedVehicles } from './recent-and-recommended-vehicles/recent-and-recommended-vehicles'
export { default as HelpMeChoose } from './help-me-choose/help-me-choose'
export { default as ValuePropositions } from './value-propositions/value-propositions'
export { UspSlide } from './usp/usp-slide'
export { USPContainer } from './usp/usp-container'
