import React, { createContext, useCallback, useContext } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { Experiment, Experiments } from '../adobe-target-server-side/types'

interface SsrExperimentsProviderProps {
  experiments: Experiments
  children?: React.ReactNode
}

interface SsrExperimentsContextTypes {
  getExperienceIdForExperiment: (experimentName: string) => number
}

export const SsrExperimentsContext = createContext<
  SsrExperimentsContextTypes | undefined
>(undefined)

const DEFAULT_EXPERIMENT_NOT_FOUND_ID = 0

export const SsrExperimentsProvider = ({
  experiments,
  children,
}: SsrExperimentsProviderProps) => {
  const getExperienceIdForExperiment = useCallback(
    (experimentName: string) => {
      const experiment = experiments.find(
        (experiment: Experiment) => experiment.name === experimentName,
      )

      // only record experiments that exist in Datadog to make the feature flag view clearer
      if (experiment?.experienceId !== undefined) {
        // replace hyphens with underscores to make querying work correctly in Datadog
        const datadogExperimentName = `experiment_${experimentName.replace(
          /-/g,
          '_',
        )}`
        const datadogExperienceId =
          experiment.experienceId === 0
            ? 'Control'
            : `Variant ${experiment.experienceId}`

        datadogRum.addFeatureFlagEvaluation(
          datadogExperimentName,
          datadogExperienceId,
        )
      }
      return experiment
        ? Number(experiment.experienceId)
        : DEFAULT_EXPERIMENT_NOT_FOUND_ID
    },
    [experiments],
  )

  return (
    <SsrExperimentsContext.Provider value={{ getExperienceIdForExperiment }}>
      {children}
    </SsrExperimentsContext.Provider>
  )
}

export const useSsrExperiments = (): SsrExperimentsContextTypes => {
  const context = useContext(SsrExperimentsContext)

  if (!context) {
    throw new Error(
      'useSsrExperiments must be used within a SsrExperimentProvider',
    )
  }

  return context
}
