/* eslint-disable unicorn/no-useless-undefined */
import { dashify, formatNumber } from '@cinch-labs/shared-util'
import { Env, readFromEnv } from '@cinch-nx/environments'
import type { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { FieldOption, Makes, Models } from '../../../types/feature-home-page'
import type { createTrackers } from './tracking'
import { decorateOptions } from '../utils/decorate-options'
import { Bucket } from '../../../types/vehicles-data'
import { Datadog } from '@cinch-nx/landing-ui-shared'
import { SearchResponse } from '@cinch-nx/data-search'

const { Actions } = Datadog

export type onChangeTracking = ReturnType<typeof createTrackers>['onChange']
type onSubmitTracking = ReturnType<typeof createTrackers>['onSubmit']

const formatPrice = (price: number | undefined) => (price ? `£${price}` : 'any')
export const TOTAL_PRICE = 'total-price'
export const MONTHLY_PRICE = 'monthly-price'

export function getSearchButtonText(quantity: number): string {
  return quantity === 1
    ? 'Search 1 car'
    : `Search ${formatNumber(quantity)} cars`
}

export function onMakeSelected({
  setSelectedMake,
  setSelectedModel,
  setMakeOptions,
  section,
  handleOnChangeTracking,
  event,
}: {
  setSelectedMake: Dispatch<SetStateAction<string | undefined>>
  setSelectedModel: Dispatch<SetStateAction<string | undefined>>
  setMakeOptions: Dispatch<SetStateAction<FieldOption[]>>
  handleOnChangeTracking: onChangeTracking
  event: ChangeEvent<HTMLSelectElement>
  section?: string
}) {
  const isPlaceholderSelected = event.target.value === 'Select make'

  const value =
    event.target.value === 'Any' || isPlaceholderSelected
      ? undefined
      : event.target.value

  handleOnChangeTracking({
    trackEventLabel: 'make',
    trackEventAction: 'select',
    value,
    datadogActionName: Actions.SELECT_MAKE_OPTION,
    trackEventSection: section,
  })

  setSelectedMake(value || '')
  setMakeOptions((makes) =>
    removeStockCountFromSelectedMake(makes, event.target.value),
  )
  setSelectedModel(undefined)
}

export function onModelSelected({
  setSelectedModel,
  handleOnChangeTracking,
  event,
  section,
}: {
  setSelectedModel: Dispatch<SetStateAction<string | undefined>>
  handleOnChangeTracking: onChangeTracking
  event: ChangeEvent<HTMLSelectElement>
  section?: string
}) {
  const value = event.target.value === 'Any' ? undefined : event.target.value
  handleOnChangeTracking({
    trackEventLabel: 'model',
    trackEventAction: 'select',
    value,
    datadogActionName: Actions.SELECT_MODEL_OPTION,
    trackEventSection: section,
  })
  setSelectedModel(value || '')
}

export function onSubmit({
  handleOnSubmitTracking,
  maxTotalPrice,
  radioChecked,
  selectedMake,
  selectedModel,
  event,
  section,
}: {
  maxTotalPrice?: number
  radioChecked?: string
  selectedMake?: string
  selectedModel?: string
  event: React.FormEvent<HTMLFormElement>
  handleOnSubmitTracking: onSubmitTracking
  section?: string
}) {
  const price =
    radioChecked === MONTHLY_PRICE
      ? `monthly price: ${formatPrice(maxTotalPrice)}`
      : `total price: ${formatPrice(maxTotalPrice)}`

  const shouldShowFinanceTypeFilter =
    readFromEnv(Env.SearchFinanceTypeFilter) === 'true'

  handleOnSubmitTracking(selectedMake, selectedModel, price, section)
  event.preventDefault()

  const urlParams = new URLSearchParams()

  if (maxTotalPrice && maxTotalPrice !== -1) {
    urlParams.append('toPrice', maxTotalPrice.toString())
  }

  if (radioChecked === MONTHLY_PRICE) {
    shouldShowFinanceTypeFilter
      ? urlParams.append('financeType', 'any')
      : urlParams.append('useMonthly', 'true')
  }

  let url = `/used-cars`

  if (selectedMake && selectedMake !== 'any') {
    url += `/${dashify(selectedMake)}`
  }

  if (
    selectedModel &&
    selectedModel !== 'any' &&
    selectedMake &&
    selectedMake !== 'any'
  ) {
    url += `/${dashify(selectedModel)}`
  }

  window.location.assign(
    `${url}${
      urlParams.toString().length > 0 ? `?${urlParams.toString()}` : ''
    }`,
  )
}

export async function updateSearchButtonLabel({
  selectedMake,
  selectedModel,
  radioChecked,
  maxTotalPrice,
  setButtonText,
}: {
  selectedMake?: string
  selectedModel?: string
  radioChecked: string
  maxTotalPrice: number
  setButtonText: Dispatch<SetStateAction<string>>
}) {
  const urlParams = new URLSearchParams({ pageSize: '0' })
  const shouldShowFinanceTypeFilter =
    readFromEnv(Env.SearchFinanceTypeFilter) === 'true'

  if (selectedMake && selectedMake !== 'any') {
    urlParams.append('make', selectedMake)
  }

  if (
    selectedModel &&
    selectedModel !== 'any' &&
    selectedMake &&
    selectedMake !== 'any'
  ) {
    urlParams.append('model', selectedModel)
  }

  if (radioChecked === MONTHLY_PRICE) {
    shouldShowFinanceTypeFilter
      ? urlParams.append('financeType', 'any')
      : urlParams.append('useMonthly', 'true')
  }

  if (maxTotalPrice !== undefined && maxTotalPrice > 0) {
    urlParams.append('toPrice', maxTotalPrice.toString())
  }
  urlParams.sort()
  const response = await getSearchResults(urlParams)
  const buttonText = getSearchButtonText(response.searchResultsCount)

  setButtonText(buttonText)
}

export async function updateStockLevels({
  selectedMake,
  selectedModel,
  radioChecked,
  maxTotalPrice,
  totalStock,
  setMakeOptions,
  setModelOptions,
  setPriceBucket,
  setMonthlyPriceBucket,
}: {
  selectedMake?: string
  selectedModel?: string
  radioChecked?: string
  totalStock?: number
  maxTotalPrice?: number
  setMakeOptions: Dispatch<SetStateAction<FieldOption[]>>
  setModelOptions: Dispatch<SetStateAction<FieldOption[]>>
  setPriceBucket: Dispatch<SetStateAction<Bucket>>
  setMonthlyPriceBucket: Dispatch<SetStateAction<Bucket>>
}) {
  const urlParams = new URLSearchParams({ pageSize: '0' })
  const shouldShowFinanceTypeFilter =
    readFromEnv(Env.SearchFinanceTypeFilter) === 'true'

  if (selectedMake && selectedMake !== 'any') {
    urlParams.append('make', selectedMake)
  }
  if (
    selectedModel &&
    selectedModel !== 'any' &&
    selectedMake &&
    selectedMake !== 'any'
  ) {
    urlParams.append('model', selectedModel)
  }

  if (radioChecked === MONTHLY_PRICE) {
    shouldShowFinanceTypeFilter
      ? urlParams.append('financeType', 'any')
      : urlParams.append('useMonthly', 'true')
  }

  if (maxTotalPrice !== undefined && maxTotalPrice > 0) {
    urlParams.append('toPrice', maxTotalPrice.toString())
  }
  urlParams.sort()
  const response = await getSearchResults(urlParams)

  const { makes } = response.facets as {
    makes: {
      name: string
      doc_count: number
      models: { name: string; doc_count: number }[]
    }[]
  }

  if (maxTotalPrice !== undefined) {
    totalStock = response.searchResultsCount
  }

  const makeOptions = decorateOptions(
    makes
      .map((data) => toMakes(data))
      .map((make) => {
        let label = `${make.name}`

        if (make.name !== selectedMake) {
          label += ` (${formatNumber(make.stock)})`
        }

        return {
          label: label,
          value: make.name,
          count: `${make.stock}`,
        }
      }),
    totalStock || 0,
  )

  setMakeOptions(makeOptions)

  if (selectedMake && selectedMake !== 'any') {
    const make = makes
      .map((data) => toMakes(data))
      .find((make) => make.name === selectedMake)

    const modelTotalStock = response.searchResultsCount

    const modelOptions = modelToOptions(make?.models ?? [], selectedModel)
    const decoratedModelOptions = decorateOptions(
      modelOptions,
      modelTotalStock || 0,
    )
    setModelOptions(decoratedModelOptions)
  }

  setPriceBucket(response.facets.priceBucket)
  setMonthlyPriceBucket(response.facets.monthlyPriceBucket)
}

export function updateModelOptions({
  selectedMake,
  selectedModel,
  makes,
  setModelOptions,
}: {
  selectedMake?: string
  selectedModel?: string
  makes: Makes[]
  setModelOptions: Dispatch<SetStateAction<FieldOption[]>>
}) {
  if (selectedMake && !/any/gi.test(selectedMake)) {
    const make = makes.find((make) => make.name === selectedMake) as Makes
    setModelOptions(modelToOptions(make.models, selectedModel))
    return
  }
  setModelOptions([])
}

function modelToOptions(
  models: Models[],
  selectedModel?: string,
): FieldOption[] {
  return models.map((model) => {
    let label = `${model.name}`

    if (model.name !== selectedModel) {
      label += ` (${formatNumber(model.stock)})`
    }

    return {
      label,
      value: model.name,
      count: `${model.stock}`,
    }
  })
}

export async function getSearchResults(urlParams: URLSearchParams) {
  const VEHICLE_SEARCH_URL = `${readFromEnv(Env.SearchServiceUrl)}/vehicles`
  const url = `${VEHICLE_SEARCH_URL}?${urlParams.toString()}`

  const response = await memoizedFetchJson(url)

  return response as SearchResponse
}

const fetchCacheMap = new Map<string, Promise<SearchResponse>>()

export function addInitialFetchCacheResponse(response: SearchResponse) {
  const defaultParams = new URLSearchParams({
    pageSize: '0',
    financeType: 'any',
  })
  defaultParams.sort()
  const VEHICLE_SEARCH_URL = `${readFromEnv(Env.SearchServiceUrl)}/vehicles`
  const url = `${VEHICLE_SEARCH_URL}?${defaultParams.toString()}`
  fetchCacheMap.set(url, Promise.resolve(response))
}
export function clearFetchCache() {
  fetchCacheMap.clear()
}
export async function memoizedFetchJson(url: string): Promise<SearchResponse> {
  const cachedResponse = fetchCacheMap.get(url)
  if (cachedResponse) {
    return cachedResponse
  }

  // don't use await as we want to cache the promise itself
  const response = fetch(url)
    .then((response) => response.json() as Promise<SearchResponse>)
    .catch((error) => {
      // if we get an error response, remove it from the cache and re-throw to reject the promise
      fetchCacheMap.delete(url)
      throw error
    })

  fetchCacheMap.set(url, response)

  return response
}

export function toMakes(make: {
  name: string
  doc_count: number
  models: { name: string; doc_count: number }[]
}): Makes {
  return {
    name: make.name,
    stock: make.doc_count,
    models: make.models.map((model) => ({
      name: model.name,
      stock: model.doc_count,
    })),
  }
}

function removeStockCountFromSelectedMake(
  makes: FieldOption[],
  selectedMake: string,
) {
  if (selectedMake && selectedMake !== 'any') {
    return makes.map((make) => {
      if (make.value === selectedMake) {
        make.label = selectedMake
      }

      return make
    })
  }
  return makes
}
